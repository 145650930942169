
import bartender from '../../media/services/bartender.png';
import catering from '../../media/services/catering.png';
import chef from '../../media/services/chef.png';
import waiter from '../../media/services/waiter.png';

export default {
    bartender,
    catering,
    chef,
    waiter
}