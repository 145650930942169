import bbq from '../../media/packages/bbq.jpeg';
import cocktailParty from '../../media/packages/cocktail-party.jpeg';
import fineDining from '../../media/packages/fine-dining.jpeg';
import french from '../../media/packages/french.jpeg';
import italian from '../../media/packages/italian.jpeg';
import mediterranean from '../../media/packages/mediterranean.jpeg';
import mexican from '../../media/packages/mexican.jpeg';

export default {
    bbq,
    cocktailParty,
    fineDining,
    french,
    italian,
    mediterranean,
    mexican,
}